const Lote = ({lote, posicion}) => {
    return ( 
        <>
            <div className={(posicion%2 === 0 ? "lineaPar " : "lineaImpar ") + "grid-cell-center"}><span>{lote.cd_lote}</span></div>
            <div className={(posicion%2 === 0 ? "lineaPar " : "lineaImpar ") + "grid-cell-center"}><span>{lote.lote_proveedor}</span></div>
            <div className={(posicion%2 === 0 ? "lineaPar " : "lineaImpar ") + "grid-cell-center"}><span>{lote.fecha_caducidad}</span></div>
            <div className={(posicion%2 === 0 ? "lineaPar " : "lineaImpar ") + "grid-cell-center"}><span>{lote.existencias1}</span></div>
            <div className={(posicion%2 === 0 ? "lineaPar " : "lineaImpar ") + "grid-cell-center"}><span>{lote.existencias2}</span></div>
        </>
     );
}
 
export default Lote;