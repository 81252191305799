import axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppContext from '../../context/AppContext';

const Login = ({urlOrigen}) => {

    const navigate = useNavigate();
    const {setToken, setRedirigirLogin, axiosInstance} = useContext(AppContext);
    const refBotonLogin = useRef();
    const [mostrarError, setMostrarError] = useState(false);
    const [ textoError, setTextoError ] = useState('');
    const [login, setLogin] = useState({
        user: "",
        pass: ""
    });

    useEffect(() => {
        setMostrarError(false)
        const checkTocken = async () => {
            try {
                const res = await axiosInstance.post(`${process.env.REACT_APP_URLAPI}/Login/checkToken`, null)
                
                if(res.status === 200) {
                    if (!res.data) {
                        sessionStorage.setItem('tokenClearERP', null)
                        sessionStorage.setItem('refreshTokenClearERP', null)
                        setRedirigirLogin(true);
                        setToken(undefined)
                    } else {
                        setRedirigirLogin(false);
                        setToken({
                            token: sessionStorage.getItem('tokenClearERP'),
                            refreshToken: sessionStorage.getItem('refreshTokenClearERP')
                        })

                        if (urlOrigen === '/login')
                            navigate('/')
                        else
                            navigate(-1)
                    }
                } 
            } catch (error) {
                if (error.response && error.response.status === 403 ) {
                    setTextoError(error.response.data.message)
                    setMostrarError(true)
                } else {
                    setTextoError(error.message)
                    setMostrarError(true)
                }
            }
        }
        checkTocken();
        //eslint-disable-next-line
    }, []);
    
    const handleClick = async (e) => {
        e.preventDefault();
        setMostrarError(false)
        try {
            const res = await axios.post(`${process.env.REACT_APP_URLAPI}/Login/Login`, login)
            if(res.status === 200) {
                sessionStorage.setItem('tokenClearERP',res.data.token)
                sessionStorage.setItem('refreshTokenClearERP',res.data.refreshToken)
                setRedirigirLogin(false);
                setToken(res.data)
                
                if (urlOrigen === '/login')
                    navigate('/')
                else
                    navigate(-1)
            } 
        } catch (error) {
            if (error.response && error.response.status === 403 ) {
                setTextoError(error.response.data.message)
                setMostrarError(true)
            } else {
                setTextoError(error.message)
                setMostrarError(true)
            }
        }
    }

    const handleChange = (e) => {
        if (mostrarError)
            setMostrarError(false)
        setLogin({
            ...login,
            [e.target.name]: e.target.value
        })
    }

    const handleKeyUp = (e) => {
        e.preventDefault();
        if (e.keyCode === 13)
            refBotonLogin.current.click();
            
    }
    return ( 
        <section className="shadow-box section-minimal">
            <div className="grid-login">
                <div>Usuario:</div>
                <input type="text" name="user" value={login.user} onChange={handleChange} onKeyUp={handleKeyUp}></input>
                <div>Contraseña:</div>
                <input type="password" name="pass" value={login.pass} onChange={handleChange} onKeyUp={handleKeyUp}></input>
                <button ref={refBotonLogin} type="submit" className="btn btn-primary btn-pd-v-5" onClick={handleClick}>Login</button>
            </div>
            {mostrarError ?
                <div className="error padding-std">{textoError}</div>
            : null }
        </section>
     );
}
 
export default Login;