import { Fragment, useContext, useEffect, useState } from "react";
import AppContext from "../context/AppContext";
import Loading from "./Loading";
import Articulo from "./Articulo";
import { exportarArticulosExcel } from "../utils/articulosUtils";

const ListaArticulos = () => {

    const {config, loading, axiosInstance} = useContext(AppContext)

    const [listaArticulos, setListaArticulos] = useState([]);

    //Carga los articulos de la empresa seleccionada
    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        const getArticulos = async () => {
            try {
                const resp = await axiosInstance.get(
                    `${process.env.REACT_APP_URLAPI}/Articulos/ArticulosConLote?almacen=${config.almacenes[config.proyecto]}`,
                    {
                        signal
                    });

                if (resp.data.length > 0) {
                    setListaArticulos(resp.data);
                }
            } catch(error) {
                console.error("No se pudo obtener la lista de Articulos: " + error.message)
            }
        }
        if (config.proyecto)
            getArticulos();

        return(() => {
            controller.abort("Cancelado por usuario");
        })
        //eslint-disable-next-line 
    }, [config])

    const handleClickExportar = (e) => {
        exportarArticulosExcel(listaArticulos, config.proyecto)
    }
    
    return ( 
        <Fragment>
            <div className="cuerpo">
                {/* <FiltroEntrega
                    filtroFechasEntrega={filtroFechasEntrega}
                    setFiltroFechasEntrega={setFiltroFechasEntrega}
                    setActualizarLista={setActualizarLista}
                /> */}
                {loading ? <Loading/> :
                    <section className="shadow-box section-lista-articulos">
                        {listaArticulos.length > 0 ?
                        <>
                        <div className="grid-lista-articulos">
                            <div className="grid-head grid-cell-center">Código articulo</div>
                            <div className="grid-head grid-cell-center">Descripción</div>
                            {/* <div className="grid-head grid-cell-center">Referencia proveedor</div> */}
                            <div className="grid-head grid-cell-center">Código barras</div>
                            <div className="grid-head grid-cell-center">Existencias</div>
                            <div className="grid-head grid-cell-center">Disponible</div>
                            <div className="grid-head grid-cell-center">Unidades</div>
                            <div className="grid-head grid-cell-center">Unidades disponibles</div>
                            {listaArticulos.map((articulo, index) => (
                            <Articulo key={index}
                                posicion={index}
                                articulo={articulo}
                            />
                            ))}
                        </div>
                        <div className="btn-align-right">
                            <button onClick={handleClickExportar} className="btn btn-primary btn-pd-v-5">Exportar</button>
                        </div>
                        </>
                        :
                        <div className="seccionLineas">
                            <h3 className="text-center">No existen articulos para mostrar</h3> 
                        </div>
                        }
                    </section>
                }
            </div>
        </Fragment>
        
     );
}
 
export default ListaArticulos;