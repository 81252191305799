const initRequestPendientes = (initstate) => {
    return initstate;
}

const reducerRequestPendientes = (state, action) => {
    switch (action.type) {
        case "ADD":
            return state + 1;
        case "REMOVE":
            return state - 1;
        case "RESET":
            return initRequestPendientes(0);
    
        default:
            return state;
    }
}

export { reducerRequestPendientes, initRequestPendientes };