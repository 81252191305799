import axios from 'axios';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowDownShortWide, faArrowDownWideShort, faBars } from '@fortawesome/free-solid-svg-icons'
import CrearPedido from "./components/CrearPedido";
import { BrowserRouter as Router, Route, Link, Routes, Navigate } from 'react-router-dom';
import ListaPedidos from "./components/ListaPedidos";
import { Fragment, useEffect, useReducer, useRef, useState } from "react";
import Cabecera from "./components/Cabecera";
import AppContext from "./context/AppContext"
import Login from "./components/login/Login";
import { initRequestPendientes, reducerRequestPendientes } from './reducers/ReducerRequestPendientes';
import ListaArticulos from './components/ListaArticulos';

library.add(faArrowDownShortWide, faArrowDownWideShort, faBars)

function App() {

  const [token, setToken] = useState(undefined);
  const [almacenes, setAlmacenes] = useState({});
  const [config, setConfig] = useState({});
  //eslint-disable-next-line
  const [urlOrigen, setUrlOrigen] = useState(window.location.pathname);
  const [redirigirLogin, setRedirigirLogin] = useState(true);
  const [loading, setLoading] = useState(false);
  const [requestPendientes, dispatchRequestPendientes] = useReducer(reducerRequestPendientes, 0, initRequestPendientes)

  const menu = useRef(null);
  const main = useRef(null);

  const axiosInstance = axios.create();

  //Agrega los headers a todas las llamadas realizadas con la instancia de axios y suma 1 al contador de request
  axiosInstance.interceptors.request.use((request) => {
    if (!request.url.includes('Albaranes/Get') && !request.url.includes('get-info-pedido'))
      dispatchRequestPendientes({ type: 'ADD' })
    request.headers = {
      ...request.headers,
      'access-token': sessionStorage.getItem('tokenClearERP'),
      'refresh-token': sessionStorage.getItem('refreshTokenClearERP')
    }
    return request
  }, error => {
    Promise.reject(error)
  });

  useEffect(() => {
    try {
      const token = {
        token: sessionStorage.getItem('tokenClearERP'),
        refreshToken: sessionStorage.getItem('refreshTokenClearERP')
      }
      setToken(token)
    } catch {

    }
  }, [])

  useEffect(() => {
    const updateMain = () => {
      if (main.current !== null) {
        const mainApp = main.current;
        if (!redirigirLogin) {
          if (window.innerWidth <= 1300)
            mainApp.classList.remove('side-nav-show')
          else
            mainApp.classList.add('side-nav-show')
        } else {
          mainApp.classList.remove('side-nav-show')
        }
      }
    }
    updateMain()
    window.addEventListener('resize', updateMain)

  }, [redirigirLogin])


  /**
   * Comprueba si la llamada ha fallado y porque motivo
   * En caso de necesitarlo, y poder, refresca el token y vuelve a hacer la llamada fallida
   * Resta 1 a las llamadas pendientes
   */
  axiosInstance.interceptors.response.use((response) => {
    if (!response.config.url.includes('Albaranes/Get') && !response.config.url.includes('get-info-pedido'))
      dispatchRequestPendientes({ type: 'REMOVE' })
    return response

  }, async (error) => {
    const originalRequest = error.config;
    if (!originalRequest.url.includes('Albaranes/Get') && !originalRequest.url.includes('get-info-pedido'))
      dispatchRequestPendientes({ type: 'REMOVE' })

    if (error.response.status === 401) {
      sessionStorage.setItem('tokenClearERP', null)
      sessionStorage.setItem('refreshTokenClearERP', null)
      setRedirigirLogin(true);
      setToken(undefined)
    }


    return Promise.reject(error);
  });

  useEffect(() => {
    if (requestPendientes > 0)
      setLoading(true);
    else
      setLoading(false);
  }, [requestPendientes])

  useEffect(() => {
    const getConfig = async () => {

      try {
        const res = await axiosInstance.get(`${process.env.REACT_APP_URLAPI}/Proyectos/GetConfigProyecto`)

        if (res.status === 200) {
          setConfig({
            almacenes: res.data.almacenes,
            proyecto: res.data.proyecto,
            usuario: res.data.usuario
          });
        }
      } catch {
        console.error('No se pudo obtener el proyecto')
      }
    }
    if (!redirigirLogin && token !== undefined)
      getConfig();

    //eslint-disable-next-line
  }, [token])

  const handleClick = () => {
    if (window.innerWidth <= 1300)
      main.current.classList.remove('side-nav-show')
  }

  return (
    <AppContext.Provider
      value={{
        axiosInstance,
        config,
        token,
        almacenes,
        loading,
        setConfig,
        setToken,
        setAlmacenes,
        setRedirigirLogin
      }}
    >

      <div className="App">
        <div className="content-page">
          <Router>
            {redirigirLogin ? null :
              <div ref={menu} className="side-nav shadow-box">
                <ul>
                  <li><Link onClick={handleClick} to="/">Crear pedidos</Link></li>
                  <li><Link onClick={handleClick} to="/listaPedidos">Ver pedidos</Link></li>
                  <li><Link onClick={handleClick} to="/articulos">Inventario</Link></li>
                </ul>
              </div>
            }
            <div ref={main} className="content-main">
              <div className="main-background"></div>
              <div className="main">
                <Routes>
                  <Route exact path="/" element={
                    redirigirLogin ? <Navigate to='/login' /> :
                      <Fragment>
                        <Cabecera
                          titulo="Creación de pedidos"
                          main={main}
                        />
                        <CrearPedido />
                      </Fragment>
                  }
                  />

                  <Route path="/listaPedidos" element={
                    redirigirLogin ? <Navigate to='/login' /> :
                      <Fragment>
                        <Cabecera
                          titulo="Lista de pedidos"
                          main={main}
                        />
                        <ListaPedidos />
                      </Fragment>
                  }
                  />
                  <Route path="/articulos" element={
                    redirigirLogin ? <Navigate to='/login' /> :
                      <Fragment>
                        <Cabecera
                          titulo="Lista de artículos"
                          main={main}
                        />
                        <ListaArticulos />
                      </Fragment>
                  }
                  />
                  <Route path="/login" element={
                    <Fragment>
                      <Cabecera
                        titulo="Login"
                        main={main}
                      />
                      <Login
                        urlOrigen={urlOrigen}
                      />
                    </Fragment>
                  }
                  />
                </Routes>
              </div>
            </div>
          </Router>
        </div>
      </div>
    </AppContext.Provider>
  );
}

export default App;
