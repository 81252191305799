import Lote from "./Lote";

const Lotes = ({lotes}) => {
    return ( 
        <>
        <div className="grid-lista-lotes">
            <div className="grid-head grid-cell-center">Código lote</div>
            <div className="grid-head grid-cell-center">Lote</div>
            <div className="grid-head grid-cell-center">Fecha caducidad</div>
            <div className="grid-head grid-cell-center">Existencias</div>
            <div className="grid-head grid-cell-center">Unidades</div>
            {lotes.map((lote, index) => (
            <Lote key={index}
                posicion={index}
                lote={lote}
            />
            ))}
        </div>
            </>
     );
}
 
export default Lotes;