import { useContext, useEffect, useRef } from "react";
import PedidoContext from "../context/PedidoContext";

function ModalRespuestaCreacion(props) {
    
    const {respuestaCreacion} = useContext(PedidoContext);
    const modalRef = useRef(null);

    //muestra u oculta el modal con la respuesta de la creacion
    useEffect(() => {
        const modal = modalRef.current;
        if (modal.current !== null) {
            if (props.mostrarModal && respuestaCreacion.resultado !== undefined)
                modal.style.display = "block";
            else
                modal.style.display = "none";
        }
        //eslint-disable-next-line 
    }, [props.mostrarModal])

    //Cierra la ventana modal
    const handleClick = () => {
        props.setMostrarModal(false);
    }
    
    return (
            <div
                ref={modalRef}
                className="modal"
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title">{respuestaCreacion.inserccion !== undefined ? "Pedido creado" : "Error al crear el pedido"}</h3>
                        <div onClick={handleClick} className="cerrar cruz-cerrar-modal"><span>&times;</span></div>
                    </div>
                    <div className="modal-body">
                        {respuestaCreacion.inserccion !== undefined ?
                            <div className="grid-container-modal">
                                <div className="grid-row">
                                    <div className="grid-col"><strong>Serie: </strong>{respuestaCreacion.inserccion[0].serie_pedido}</div>
                                    <div className="grid-col"><strong>Número de pedido: </strong>{respuestaCreacion.inserccion[0].num_pedido}</div>
                                </div>
                                <div className="grid-row">
                                    <div className="grid-col"><strong>Almacen de creación: </strong>{respuestaCreacion.inserccion[0].almacen_origen}</div>
                                    <div className="grid-col"><strong>Ejercicio del pedido: </strong>{respuestaCreacion.inserccion[0].ejercicio_pedido}</div>
                                </div>
                            </div>
                        : 
                            <div className="">
                                    <div className="">{respuestaCreacion.txt_error}</div>
                            </div>
                        }
                    </div>
                    <div className="modal-footer">
                        <button onClick={handleClick} className="btn btn-primary btn-cerrar">Cerrar</button>
                    </div>
                </div>
            </div>
    );
  }

  export default ModalRespuestaCreacion;