import { Fragment, useContext, useEffect, useState } from "react";
import AppContext from "../context/AppContext";
import PedidoContext from "../context/PedidoContext";

function Provincias () {

    const {direccionEntrega, respuestaCreacion, dispatchDireccionEntrega, cliente} = useContext(PedidoContext);
    const {axiosInstance} = useContext(AppContext);

    const [provincias, setProvincias] = useState([]);

    //Obtiene las provincias diponibles para el pais seleccionado
    useEffect(() => {
        const getProvincias = async () => {
            try {
                if (direccionEntrega.pais.cd_pais !== ""){
                    const resp = await axiosInstance.get(`${process.env.REACT_APP_URLAPI}/Direccion/Provincias`);

                    if (resp.status === 200 && Object.keys(resp.data).length > 0)
                        setProvincias(resp.data.provincias.filter((provincia) => {
                            if (direccionEntrega.pais.cd_pais === "ES") {
                                if (provincia.cd_pais === direccionEntrega.pais.cd_pais && provincia.cd_provincia <= 52)
                                    return true
                                else
                                    return false;
                            } else {
                                if (provincia.cd_pais === direccionEntrega.pais.cd_pais)
                                    return true
                                else
                                    return false;
                            }
                        }));
                
                } else { //En caso de que el pais este vacío limpian las provincias
                    setProvincias([]);
                }
            } catch {
                console.error("No se pudo obtener la lista de provincias")
            }
        }
        getProvincias();
        //eslint-disable-next-line 
    },[direccionEntrega.pais])

    //Selecciona por defecto la primera provincia disponible
    useEffect(() => {
        if (provincias.length > 0) {
            if (cliente && cliente.cd_cliente === "402") {
                dispatchDireccionEntrega({type: 'MODIFY_STATE', provincia: 15, provincias: provincias});
            } else {
                dispatchDireccionEntrega({type: 'MODIFY_STATE', provincia: provincias[0].cd_provincia, provincias: provincias});
            }
        }
    //eslint-disable-next-line
    }, [provincias])

    useEffect(() => {
        if (respuestaCreacion.resultado !== undefined && respuestaCreacion.resultado === "1")
            dispatchDireccionEntrega({type: 'MODIFY_STATE', provincia: provincias[0].cd_provincia, provincias: provincias});
    //eslint-disable-next-line
    }, [respuestaCreacion])

    //Establece la provincia seleccionada
    const handleChange = (e) => {
        dispatchDireccionEntrega({type: 'MODIFY_STATE', provincia: parseInt(e.target.value), provincias: provincias});
    }

    return (
        (provincias.length > 0 ?
            <Fragment>
                <div className="label provincia">Provincia: </div>
                <select className="selector provincia" onChange={handleChange} value={direccionEntrega.provincia.cd_provincia}>
                    {provincias.map((provincia, index) => (
                        <option key={index} value={provincia.cd_provincia}>{provincia.nombre}</option>
                    ))}
                </select>
            </Fragment>
        : null)
    );
}

export default Provincias;