const FiltroEntrega = ({filtroFechasEntrega, setFiltroFechasEntrega, onClickActualizar}) => {

    const handleChange = (e) => {console.log({e});
        setFiltroFechasEntrega({
            ...filtroFechasEntrega,
            [e.target.name]: e.target.value
        })
    }
    return ( 
        <section className="shadow-box padding-std">
            <div className="grid-fechas-entrega">
                <div className="grid-label valign-middle">
                    <span>Desde fecha de entrega: </span>
                </div>
                <div className="grid-value valign-middle">
                    <input onChange={handleChange} type="date" name="desde_fecha" value={filtroFechasEntrega.desde_fecha}/>
                </div>

                <div className="grid-label valign-middle">
                    <span>Hasta fecha de entrega: </span>
                </div>
                <div className="grid-value valign-middle">
                    <input onChange={handleChange} type="date" name="hasta_fecha" value={filtroFechasEntrega.hasta_fecha}/>
                </div>
                <div>
                    <button onClick={onClickActualizar} type="button" className="btn btn-primary btn-pd-v-5" >Actualizar</button>
                </div>
            </div>
            
        </section>
     );
}
 
export default FiltroEntrega;