import { Fragment, useContext, useEffect } from "react";
import PedidoContext from "../context/PedidoContext";
import Paises from "./Paises";
import Provincias from "./Provincias";

function DireccionEntrega() {

    const {direccionEntrega, dispatchDireccionEntrega, respuestaCreacion, cliente} = useContext(PedidoContext);

    //Resetea los datos de entrega cuando se crea un pedido
    useEffect(() => {
        if (respuestaCreacion.resultado !== undefined && respuestaCreacion.resultado === "1") {
            if (cliente && cliente.cd_cliente === "402") return;
            
            dispatchDireccionEntrega({type: 'RESET'});
        }
    //eslint-disable-next-line
    }, [respuestaCreacion])

    useEffect(() => {
        if (cliente && cliente.cd_cliente === "402") {
            dispatchDireccionEntrega({type: 'MODIFY_ALL', direccion: {
                nombre_direc_entrega: cliente.nombre_fiscal,
                direccion_direc_entrega: cliente.direccion,
                poblacion_direc_entrega: cliente.localidad,
                cp_direc_entrega: cliente.cp,
            }});
        } 
        //eslint-disable-next-line
    }, [cliente])
    

    //Actualiza la dirección de entrega del pedido
    const handleChange = (e) => {
        dispatchDireccionEntrega({type: 'MODIFY', field: [e.target.name], payload: e.target.value});
    }

    return (
        <Fragment>
            <div className="grid-direccionEntrega">
                <div className="labelDireccion">Nombre: </div><input onChange={handleChange} type="text" className="nombre campoDatos" name="nombre_direc_entrega" value={direccionEntrega.nombre_direc_entrega}/>
                <div className="labelDireccion">Dirección: </div><input onChange={handleChange} type="text" className="direccion campoDatos" name="direccion_direc_entrega" value={direccionEntrega.direccion_direc_entrega}/>
                <div className="labelDireccion">Población: </div><input onChange={handleChange} type="text" className="poblacion campoDatos" name="poblacion_direc_entrega" value={direccionEntrega.poblacion_direc_entrega}/>
                <div className="labelDireccion">CP: </div><input onChange={handleChange} step="1" type="number" className="codigoPostal campoDatos" name="cp_direc_entrega" value={direccionEntrega.cp_direc_entrega}/>
                <div className="labelDireccion">Teléfono: </div><input onChange={handleChange} type="text" className="telefono campoDatos" name="telefono_direc_entrega" value={direccionEntrega.telefono_direc_entrega}/>
                <div className="labelDireccion">E-mail: </div><input onChange={handleChange} type="text" className="email campoDatos" name="email_direc_entrega" value={direccionEntrega.email_direc_entrega}/>
                <Paises/>
                <Provincias/>
            </div>
        </Fragment>
    );
}

export default DireccionEntrega;