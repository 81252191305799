import { Fragment, useContext } from "react";
import AppContext from "../context/AppContext";
import PedidoContext from "../context/PedidoContext";

function DatosPedido() {

    const { config } = useContext(AppContext);
    const {pedido, setPedido} = useContext(PedidoContext);
    
    const handleChange = (e) => {
        setPedido({
            ...pedido,
            cabecera: {
                ...pedido.cabecera, 
                [e.target.name]: e.target.value.trim()
            }
        })
    }

    return (
        <Fragment>
            <div className="label">Número de pedido Web:</div>
            <div className="grid-value">
                <input className="inputPedido" readOnly type="text" name="num_pedido_web" value={pedido.cabecera.num_pedido_web} onChange={handleChange}/>
            </div>
            <div className="label">S/Referencia:</div>
            <div className="grid-value">
                <input className="inputPedido" type="text" name="sureferencia" value={pedido.cabecera.sureferencia} onChange={handleChange}/>
            </div>
            {config.proyecto === 'Biomasa' ? 
                <Fragment>
                    <div className="label">Agencia transporte:</div>
                    <div className="valign-middle">
                        <select className="selector" name="cd_agencia_envio" value={pedido.cabecera.cd_agencia_envio}  onChange={handleChange}>
                            <option value="310">Einsa Sourcing</option>
                            <option value="300">Agencia Biomasa</option>
                        </select>
                    </div>
                </Fragment>
            : null}
        </Fragment>
    );
}

export default DatosPedido