import { Fragment, useContext } from "react";
import PedidoContext from "../context/PedidoContext";

function ArticuloPedido({articulo, posicion}) {
    
    const {dispatchArticulosPedido} = useContext(PedidoContext);

    const handlerChange = (e) => {

        if (parseFloat(e.target.value) > parseFloat(articulo.unidades_disponibles)) 
            dispatchArticulosPedido({type: 'MODIFY_ITEM', articulo: articulo, field: [e.target.name], payload: articulo.unidades_disponibles})
        else if (parseFloat(e.target.value) < 0)
            dispatchArticulosPedido({type: 'MODIFY_ITEM', articulo: articulo, field: [e.target.name], payload: 0})
        else
            dispatchArticulosPedido({type: 'MODIFY_ITEM', articulo: articulo, field: [e.target.name], payload: e.target.value})
    }

    return (
            <Fragment >
                <div className={(posicion%2 === 0 ? "lineaPar " : "lineaImpar ") + "datosArticulo codigoArticulo"}><span>{articulo.codigo}</span></div>
                <div className={(posicion%2 === 0 ? "lineaPar " : "lineaImpar ") + "datosArticulo descripcion"}><span>{articulo.descripcion}</span></div>
                <div className={(posicion%2 === 0 ? "lineaPar " : "lineaImpar ") + "datosArticulo referenciaProveedor"}><span>{articulo.referenciaProveedor}</span></div>
                <div className={(posicion%2 === 0 ? "lineaPar " : "lineaImpar ") + "datosArticulo codigoBarras"}><span>{articulo.codigoBarras}</span></div>
                <div className={(posicion%2 === 0 ? "lineaPar " : "lineaImpar ") + "datosArticulo existencias"}><span>{articulo.unidades}</span></div>
                <div className={(posicion%2 === 0 ? "lineaPar " : "lineaImpar ") + "datosArticulo disponible"}><span>{articulo.unidades_disponibles}</span></div>
                {articulo.lotesSeleccionados?.length > 0 ?
                <div className={(posicion%2 === 0 ? "lineaPar " : "lineaImpar ") + "datosArticulo inputArticuloVenta"}>
                    <span>{articulo.cantidad}</span>
                </div>
                :
                <div className={(posicion%2 === 0 ? "lineaPar " : "lineaImpar ") + "datosArticulo inputArticuloVenta"}>
                    <input type="number" onChange={handlerChange} name="cantidad" value={articulo.cantidad}/>
                </div>
                }
                <div className={(posicion%2 === 0 ? "lineaPar " : "lineaImpar ") + "datosArticulo botonEliminar"}>
                    <button className="btn btn-danger btn-pd-v-5" onClick={() => dispatchArticulosPedido({type: 'REMOVE_ITEM', articulo: articulo})}>Eliminar</button>
                </div>
            </Fragment>
    );
}

export default ArticuloPedido;