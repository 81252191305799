const initDireccionEntrega = (initstate) => {
    return {
        nombre_direc_entrega: "",
        direccion_direc_entrega: "",
        poblacion_direc_entrega: "",
        cp_direc_entrega: "",
        telefono_direc_entrega: "",
        email_direc_entrega: "",
        pais: {
            cd_pais: "ES",
            nombre: "ESPAÑA"
        },
        provincia: {
            cd_pais: "ES",
            cd_provincia: 15,
            nombre: "LA CORUÑA"
        }
        //pais_direc_entrega: "",
        //provincia_direc_entrega: ""
    };
}

const reducerDireccionEntrega = (state, action) => {
    switch (action.type) {
        case "MODIFY_ALL":
            return {
                ...state,
                ...action.direccion
            }
        case "MODIFY":
            return {
                ...state,
                [action.field]: action.payload
            }
        case "MODIFY_COUNTRY":
            return {
                ...state,
                pais: {
                    cd_pais: action.pais,
                    nombre: action.paises.find((pais) => pais.cd_pais === action.pais).nombre
                },
                provincia: {
                    ...state.provincia,
                    cd_pais: action.pais
                }
            }
        case "MODIFY_STATE":
            return {
                ...state,
                provincia: {
                    ...state.provincia,
                    cd_provincia: action.provincia,
                    nombre: action.provincias.find((provincia) => provincia.cd_provincia === action.provincia).nombre
                    //cd_pais: action.pais,
                    //nombre: action.paises.find((pais) => pais.cd_pais === action.pais).nombre
                }
            }

        case "RESET":
            return initDireccionEntrega({});
    
        default:
            return state;
    }
}

export { reducerDireccionEntrega, initDireccionEntrega };