const initArticulosPedido = (initState) => {
    return initState;
}

const reducerArticulosPedido = (state, action) => {
    switch (action.type) {
        case "ADD_ITEM":
            const articuloFinal = {
                ...action.articulo,
                tp_linea: "1",
                precio: action.articulo.precioTarifa1,
                cantidad: action.articulo?.lotesSeleccionados?.length > 0 
                ? action.articulo?.lotesSeleccionados.reduce((acumulador, lote) => acumulador + lote.unidades, 0) 
                : 1,
                cd_articulo: action.articulo.codigo,
                descuentos_linea: "0"
            }
            return [...state.filter(articulo => articulo.codigo !== action.articulo.codigo), articuloFinal];

        case "REMOVE_ITEM":
            return state.filter(articuloPedido => articuloPedido.codigo !== action.articulo.codigo);

        case "MODIFY_ITEM":
            return state.map((articuloPedido, index) => {
                if (articuloPedido.codigo === action.articulo.codigo) {
                    return {
                        ...articuloPedido, 
                        [action.field]: action.payload
                    };
                } else {
                    return articuloPedido;
                }
            })

        case "RESET":
            return initArticulosPedido([]);
    
        default:
            return state;
    }
}

export { reducerArticulosPedido, initArticulosPedido };