import { useContext, useEffect, useState } from "react";
import AppContext from "../context/AppContext";
import PedidoContext from "../context/PedidoContext";
import Cliente from "./Cliente";

function Clientes() {

    const [ listaClientes, setListaClientes ] = useState([]);
    const { config, axiosInstance } = useContext(AppContext);
    const { setCliente } = useContext(PedidoContext); 

    useEffect(() => {
        
        const actulizaClientes = async () => {
            try {
                const resp = await axiosInstance.get(`${process.env.REACT_APP_URLAPI}/Clientes/VolcadoClientes?seleccion=T`);
                
                if (resp.status === 200 && resp.data.length > 0) {
                    const listaFinal = await resp.data.filter((cliente, index) => {
                            if (Object.keys(cliente).length > 1) {
                                if (config.proyecto !== "Cepever") {
                                    if (cliente.nombre.toUpperCase().includes(config.proyecto.toUpperCase())) {
                                        return true;
                                    } else {
                                        return false
                                    }
                                } else {
                                    return true;
                                }
                            } else {
                                return false;
                            }
                        }
                    );

                    //Establecemos la lista de clientes y seleccionamos el primero de la lista
                    if (listaFinal.length > 0) {
                        setListaClientes(listaFinal);
                        setCliente(listaFinal[0]);
                    }
                }
            } catch {
                console.error("No se pudo obtener la lista de paises")
            }
            
        }
        actulizaClientes();
        //eslint-disable-next-line 
    }, [config])

    //establecemos el cliente seleccionado
    const handleChange = (e) => {
        setCliente(listaClientes.find((cliente) => cliente.cd_cliente === e.target.value));
    }

    return (
        <div>
            {listaClientes.length > 0 ?
                <div>
                    <span>Clientes: </span><select onChange={handleChange}>
                        {listaClientes.map((cliente, index) => {
                            return (
                                <option key={index} value={cliente.cd_cliente}>{cliente.nombre}</option>
                            )
                        })}
                    </select>
                    <Cliente/>
                </div>
            : null}
      </div>
    );
  }
  
  export default Clientes;