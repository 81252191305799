import { Fragment } from "react";
import Lotes from "./Lotes";

function Articulo({articulo, posicion}) {
    return (
        <Fragment >
            <div className={(articulo.lotes && Array.isArray(articulo.lotes) ? 'articulo-inventario ' : (posicion%2 === 0 ? "lineaPar " : "lineaImpar ")) + "datosArticulo codigoArticulo grid-cell-center"}><span>{articulo.codigo}</span></div>
            <div className={(articulo.lotes && Array.isArray(articulo.lotes) ? 'articulo-inventario ' : (posicion%2 === 0 ? "lineaPar " : "lineaImpar ")) + "datosArticulo descripcion"}><span>{articulo.descripcion}</span></div>
            {/* <div className={(articulo.lotes && Array.isArray(articulo.lotes) ? 'articulo-inventario ' : (posicion%2 === 0 ? "lineaPar " : "lineaImpar ")) + "datosArticulo referenciaProveedor grid-cell-center"}><span>{articulo.referenciaProveedor}</span></div> */}
            <div className={(articulo.lotes && Array.isArray(articulo.lotes) ? 'articulo-inventario ' : (posicion%2 === 0 ? "lineaPar " : "lineaImpar ")) + "datosArticulo codigoBarras grid-cell-center"}><span>{articulo.codigoBarras}</span></div>
            <div className={(articulo.lotes && Array.isArray(articulo.lotes) ? 'articulo-inventario ' : (posicion%2 === 0 ? "lineaPar " : "lineaImpar ")) + "datosArticulo existencias grid-cell-center"}><span>{articulo.existencias}</span></div>
            <div className={(articulo.lotes && Array.isArray(articulo.lotes) ? 'articulo-inventario ' : (posicion%2 === 0 ? "lineaPar " : "lineaImpar ")) + "datosArticulo disponible grid-cell-center"}><span>{articulo.disponible}</span></div>
            <div className={(articulo.lotes && Array.isArray(articulo.lotes) ? 'articulo-inventario ' : (posicion%2 === 0 ? "lineaPar " : "lineaImpar ")) + "datosArticulo existencias2 grid-cell-center"}><span>{articulo.unidades}</span></div>
            <div className={(articulo.lotes && Array.isArray(articulo.lotes) ? 'articulo-inventario ' : (posicion%2 === 0 ? "lineaPar " : "lineaImpar ")) + "datosArticulo unidades_disponibles grid-cell-center"}><span>{articulo.unidades_disponibles}</span></div>
            {articulo.lotes && Array.isArray(articulo.lotes) && <Lotes lotes={articulo.lotes} />}
        </Fragment>
    );
}

export default Articulo;