import { Fragment, useContext, useEffect, useState } from "react";
import AppContext from "../context/AppContext";
import PedidoContext from "../context/PedidoContext";

function Articulos({articuloSeleccionado, setArticulo}) {

    const {config, axiosInstance} = useContext(AppContext)
    const {serieAlmacen, articulos, setArticulos, respuestaCreacion} = useContext(PedidoContext);

    const [listaArticulos, setListaArticulos] = useState([]);

    const getArticulos = async () => {
        try {
            const resp = await axiosInstance.get(`${process.env.REACT_APP_URLAPI}/Articulos/ArticulosConLote?almacen=${serieAlmacen}`)

            if (resp.data.length > 0) {
                setArticulos(resp.data);
                setListaArticulos(resp.data);
            }
        } catch {
            console.error("No se pudo obtener la lista de Articulos")
        }
    }

    useEffect(() => {
        if (respuestaCreacion.resultado !== undefined && respuestaCreacion.resultado === "1") 
            getArticulos()
    //eslint-disable-next-line
    }, [respuestaCreacion])

    //Carga los articulos de la empresa seleccionada
    useEffect(() => {
        setArticulos([]);
        
        if (serieAlmacen > 0)
            getArticulos();
        //eslint-disable-next-line 
    }, [config, serieAlmacen])

    //Establece por defecto en el select el primer artículo de la lista actual
    useEffect(() => {
        if (listaArticulos.length > 0) {
            setArticulo(listaArticulos[0]);
        }
        //eslint-disable-next-line 
    }, [listaArticulos])

    //selecciona el articulo indicado
    const handleChange = (e) => {
        setArticulo(articulos.find(articulo => articulo.codigo === e.target.value));
    }

    //Filtra la lista de articulos comparando el código y la descripción
    const handleChangeFilter = (e) => {
        setListaArticulos(articulos.filter(articulo => { 
            if (articulo.descripcion?.toUpperCase().includes(e.target.value.toUpperCase()) || 
            articulo.codigoBarras?.toUpperCase().includes(e.target.value.toUpperCase()) ||
            articulo.codigo?.toUpperCase().includes(e.target.value.toUpperCase()) ||
            articulo.referencia?.toUpperCase().includes(e.target.value.toUpperCase()))
                return true;
            else
                return false;
        }))
    }

    return (
        (articulos.length > 0 ?
            <Fragment>
                <div className="valign-middle"><span>Filtrar: </span></div><div className="valign-middle"><input onChange={handleChangeFilter} className="filtro-articulo"/></div>
                
                <div className="valign-middle"><span> Articulos: </span></div><div className="valign-middle"><select className="select-articulo" value={articuloSeleccionado !== undefined ? articuloSeleccionado.codigo : articulos[0].codigo } onChange={handleChange}>
                    {listaArticulos.map((articulo, index) => (
                        <option key={index} value={articulo.codigo}>{articulo.codigoBarras ? "Código Barras: " + articulo.codigoBarras + " - " : articulo.referencia ? "Referencia: " + articulo.referencia + " - " : articulo.codigo ? "Código: " + articulo.codigo + " - " : ""}Descripción: {articulo.descripcion}</option>
                    ))}
                </select></div>
            </Fragment>
        : null)
    );
}

export default Articulos;