import { useEffect, useRef } from "react";

const Modal = ({pdf, mostrarPdf, setMostrarPdf}) => {

    const modalRef = useRef(null);

    useEffect(() => {
        const modal = modalRef.current;
        if (modal.current !== null) {
            if (mostrarPdf)
                modal.style.display = "block";
            else
                modal.style.display = "none";
        }
        //eslint-disable-next-line 
    }, [mostrarPdf])

    //Cierra la ventana modal
    const handleClick = () => {
        setMostrarPdf(false);
    }

    return (
        <div
            ref={modalRef}
            className="modal"
            onClick={handleClick}
        >
            
            <div className="modal-content modal-content-pdf">
                <embed src={pdf} width="100%" height="100%" />
            </div>
        </div>
    );
}
 
export default Modal;