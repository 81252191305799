import { Fragment, useEffect, useState } from "react";
import LoteArticulo from "./LoteArticulo";

const SelectorLoteArticulo = ({articulo, setArticulo}) => {
  const sinLote = {
    cd_lote: 0
  }
  const [lotesSeleccionados, setLotesSeleccionados] = useState([]);
  const [loteSeleccionado, setLoteSeleccionado] = useState(sinLote);

  useEffect(() => {
    setArticulo({
      ...articulo,
      lotesSeleccionados: lotesSeleccionados
    });
    //eslint-disable-next-line
  }, [lotesSeleccionados])
  
  const handleClick = (e) => {
    if (loteSeleccionado.cd_lote === sinLote.cd_lote) return;

    const lote = articulo.lotes.find(lote => lote.cd_lote === loteSeleccionado.cd_lote);
    const lotes = [
      ...lotesSeleccionados?.filter(loteFitlro => loteFitlro.cd_lote !== loteSeleccionado.cd_lote),
      lote
    ]
    setLotesSeleccionados(lotes);
    setLoteSeleccionado(sinLote);
  }

  const handleSelect = (e) => {
    if (e.target.value === sinLote.cd_lote) {
      setLoteSeleccionado(sinLote);
    } else {
      setLoteSeleccionado(articulo.lotes.find(lote => lote.cd_lote === parseInt(e.target.value)));
    }
  }
  
  return ( 
      <Fragment>
        <div className="valign-middle"><span> Lotes: </span></div>
        <div className="valign-middle">
          <select 
            className="select-lote" 
            value={loteSeleccionado?.cd_lote} 
            onChange={handleSelect}
          >
            <option value={sinLote.cd_lote}>Sin lote</option>
            {articulo?.lotes?.map((lote, index) => (
                <option key={index} value={lote.cd_lote}>Lote: {lote.lote_proveedor} - Fecha caducidad: {lote.fecha_caducidad}</option>
            ))}
          </select>
          <div className="padding-std"><button disabled={loteSeleccionado?.cd_lote === sinLote.cd_lote} className={`btn btn-primary btn-articulo btn-pd-v-5`} onClick={handleClick} >Añadir lote</button></div>
        </div>
        <div className="grid-lotes-articulo-pedido">
          <div>Lote</div>
          <div>Fecha caducidad</div>
          <div>Existencias</div>
          <div>Unidades</div>
          {articulo?.lotesSeleccionados?.map(lote => <LoteArticulo key={lote.cd_lote} lote={lote} setLotesSeleccionados={setLotesSeleccionados} />)}
        </div>
      </Fragment>
   );
}
 
export default SelectorLoteArticulo;