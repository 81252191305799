import { Fragment, useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppContext from "../context/AppContext";
import { formatFecha, ordenarFechaAscendente, ordenarFechaDescendente } from "../helpers/fechas";
import FiltroEntrega from "./FiltroEntrega";
import Loading from "./Loading";
import Pedido from "./Pedido";

const ListaPedidos = () => {

    const defaultSort = {
        fecha: "",
        pedido: "",
        ejercicio: "",
        serie: "",
        almacen: "",
        cliente: "",
        situacion: "",
        referencia: "",
        fecha_entrega: ""
    }

    const { config, loading, axiosInstance } = useContext(AppContext);

    const [listaPedidos, setListaPedidos] = useState([]);
    const [listaPedidosFiltrada, setListaPedidosFiltrada] = useState([]);
    const [filtroFechasEntrega, setFiltroFechasEntrega] = useState({
        desde_fecha: formatFecha(Date.now(), -7),
        hasta_fecha: formatFecha(Date.now(), 7)
    });
    const [ordenColumnas, setOrdenColumnas] = useState({
        ...defaultSort,
        fecha_entrega: "ASC"
    });

    const getPedidos = async () => {
        if (config.proyecto !== undefined) {
            let fechaDesde = formatFecha(filtroFechasEntrega.desde_fecha, -30);
            let fechaHasta = formatFecha(filtroFechasEntrega.hasta_fecha, 10);
                
            const respuesta = await axiosInstance.get(`${process.env.REACT_APP_URLAPI}/Pedidos/GetInformePedidos` +
            `?${(config.proyecto === "Cepever" ? "" : "almacen=" + config.almacenes[config.proyecto])}` +
            `&desde_fecha=${fechaDesde}&hasta_fecha=${fechaHasta}`);

            
            if (respuesta.status === 200 && respuesta.data !== undefined) {
                setListaPedidos(respuesta.data);
            }
        }
    }

    useEffect(() => {
        getPedidos();
        //eslint-disable-next-line
    }, [config])

    useEffect(() => {
        const pedidosFiltrados = listaPedidos.filter((pedido) => {
                if (Date.parse(pedido.fecha_entrega) >= Date.parse(filtroFechasEntrega.desde_fecha) &&
                    Date.parse(pedido.fecha_entrega) <= Date.parse(filtroFechasEntrega.hasta_fecha)) {
                        return true;
                    } else {
                        return false;
                    }
            })

        pedidosFiltrados.sort((a, b) =>  ordenarFechaAscendente("fecha_entrega", a,b));
        setOrdenColumnas({
            ...defaultSort,
            fecha_entrega: "ASC"
        })
        setListaPedidosFiltrada(pedidosFiltrados);
        //eslint-disable-next-line
    }, [listaPedidos])

    const handleClick = (e) => {

        listaPedidosFiltrada.sort((a, b) => {
            if (ordenColumnas[e.target.id] === "") {

                return ordenarFechaAscendente(e.target.id, a, b);

            } else if (ordenColumnas[e.target.id] === "ASC") {

                return ordenarFechaDescendente(e.target.id, a, b);

            } else {
                if (Date.parse(a.cd_pedven) > Date.parse(b.cd_pedven)) {
                    return 1;
                }
            
                if (Date.parse(a.cd_pedven) < Date.parse(b.cd_pedven)) {
                    return -1;
                }
            
                return 0;
            }
        })

        if (ordenColumnas[e.target.id] === "") {
            setOrdenColumnas({
                ...defaultSort,
                [e.target.id]: "ASC"
            })
        } else if (ordenColumnas[e.target.id] === "ASC") {
            setOrdenColumnas({
                ...defaultSort,
                [e.target.id]: "DESC"
            })
        } else {
            setOrdenColumnas({
                ...defaultSort,
                [e.target.id]: ""
            })
        }
    }
    
    return ( 
        <Fragment>
            <div className="cuerpo">
                <FiltroEntrega
                    filtroFechasEntrega={filtroFechasEntrega}
                    setFiltroFechasEntrega={setFiltroFechasEntrega}
                    onClickActualizar={() => getPedidos()}
                />
                {loading ? <Loading/> :
                    <section className="shadow-box section-lista-pedidos">
                        {listaPedidosFiltrada.length > 0 ?
                            <div className="grid-pedidos">
                                <div id="fecha" className="grid-head sortable"  onClick={handleClick}>Fecha {ordenColumnas.fecha !== "" ? <FontAwesomeIcon icon={ordenColumnas.fecha === "ASC" ? ['fa', 'arrow-down-short-wide'] : ['fa', 'arrow-down-wide-short']} /> : null}</div>
                                <div className="grid-head">Nº Pedido</div>
                                <div className="grid-head">Ejercicio</div>
                                <div className="grid-head">Serie</div>
                                <div className="grid-head">Almacén</div>
                                <div className="grid-head">Cliente</div>
                                <div className="grid-head">Situación</div>
                                <div className="grid-head">S/Referencia</div>
                                <div className="grid-head">Referencia</div>
                                <div id="fecha_entrega" className="grid-head sortable"  onClick={handleClick}>Fecha de entrega {ordenColumnas.fecha_entrega !== "" ? <FontAwesomeIcon icon={ordenColumnas.fecha_entrega === "ASC" ? ['fa', 'arrow-down-short-wide'] : ['fa', 'arrow-down-wide-short']} /> : null} </div>
                                <div className="grid-head">Nº Albarán</div>
                                <div className="grid-head"></div>
                                    {listaPedidosFiltrada.map((pedido, index) => (
                                        <Pedido key={index} pedido={pedido} esPar={index%2} config={config} />
                                    ))}
                            </div>
                        : 
                            <div className="padding-std text-center text-bold">
                                No se ha encontrado ningun pedido
                            </div>
                        }
                    </section>
                }
            </div>
        </Fragment>
        
     );
}
 
export default ListaPedidos;