import { useContext, useEffect, useRef } from "react";
import PedidoContext from "../context/PedidoContext";


function Error({errores, mostrarErrores, setErrores}) {

    const {pedido, articulosPedido, direccionEntrega} = useContext(PedidoContext);
    const refSection = useRef(null);

    //Comprueba los campos obligatorios para mostrar errores en caso de intentar crear un pedido sin indicarlos
    useEffect(() => {
        if (mostrarErrores) {
            
        refSection.current.style.display = "inherit";
        const compruebaErrores = setTimeout(() => {
            
            let listaErrores = {};

            if(pedido.cabecera.num_pedido_web === "") {
                listaErrores = {
                    ...listaErrores,
                    errorPedidoWeb: "El identificador de pedido web es obligatorio"
                };
            } else {
                listaErrores = {
                    ...listaErrores,
                    errorPedidoWeb: ""
                }
            }

            if(articulosPedido.length === 0) {
                listaErrores = {
                    ...listaErrores,
                    errorArticulosPedido: "El pedido no puede ir sin artículos"
                }
            } else {
                listaErrores = {
                    ...listaErrores,
                    errorArticulosPedido: ""
                }
            }

            if(direccionEntrega.nombre_direc_entrega === "" ||
            direccionEntrega.direccion_direc_entrega === "" ||
            direccionEntrega.poblacion_direc_entrega === "" ||
            direccionEntrega.cp_direc_entrega === "" ||
            direccionEntrega.pais.nombre === "" ||
            direccionEntrega.provincia.nombre === "") {
                listaErrores = {
                    ...listaErrores,
                    errorDireccionEntrega: "Los dirección de entrega es obligatoria"
                }
            } else {
                listaErrores = {
                    ...listaErrores,
                    errorDireccionEntrega: ""
                }
            }

            if(pedido.cabecera.fecha_entrega === "") {
                listaErrores = {
                    ...listaErrores,
                    errorFechaEntrega: "La fecha de entrega es obligatoria"
                }
            } else {
                listaErrores = {
                    ...listaErrores,
                    errorFechaEntrega: ""
                }
            }

            setErrores(listaErrores);
            
            if (listaErrores.errorPedidoWeb === "" &&
            listaErrores.errorArticulosPedido === "" &&
            listaErrores.errorDireccionEntrega === "" &&
            listaErrores.errorFechaEntrega === "") {
                refSection.current.style.display = "none";
            }
                

          }, 1000)
      
        return () => clearTimeout(compruebaErrores);

        } else {
            refSection.current.style.display = "none";
        }

        //eslint-disable-next-line 
    }, [articulosPedido, direccionEntrega, pedido.cabecera, mostrarErrores])
    
    return (
        <section ref={refSection} className="shadow-box" >
            {Object.values(errores).map((error, index) => (
                (error !== "" ? <div key={index} className="error">* {error}</div> : null)
            ))}
        </section>
    );
}

export default Error;