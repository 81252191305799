import { Fragment, useContext, useEffect } from "react";
import AppContext from "../context/AppContext";
import PedidoContext from "../context/PedidoContext";

function Almacenes() {

    const {setSerieAlmacen} = useContext(PedidoContext)
    const {config} = useContext(AppContext)

    useEffect(() => {
        setSerieAlmacen(() =>{ for (let almacen in config.almacenes) return config.almacenes[almacen];})
    // eslint-disable-next-line
    }, [config])

    //usamos el mismo almacen y serie
    const handleChange = (e) => {
        setSerieAlmacen(e.target.value);
    }

    return (
        <Fragment>
            <div className="label">Almacen: </div>
            <div className="grid-value">
                <select className="selector"  onChange={handleChange}>
                    {Object.keys(config.almacenes).map((almacen) => (
                        <option key={almacen} value={config.almacenes[almacen]}>{almacen}</option>
                    ))}
                </select>
            </div>
        </Fragment>
    );
}

export default Almacenes;