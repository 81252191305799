import { Fragment, useContext, useEffect, useRef, useState } from "react"
import AppContext from "../context/AppContext";
import ModalPdf from "./ModalPdf";

import ModalP from "./ModalPedido.js";

const Pedido = ({ pedido, esPar }) => {

    const { axiosInstance } = useContext(AppContext);
    const [mostrarModal, setMostrarModal] = useState(false);
    const refBotonAlbaran = useRef(null);
    const [pdf, setPdf] = useState(null);
    const [mostrarPdf, setMostrarPdf] = useState(false);
    const [albaran, setAlbaran] = useState({});
    const [lineasPedido, setLineasPedido] = useState([]);
    const [error, setError] = useState("");
    const [pais, setPais] = useState(null);
    const [provincia, setProvincia] = useState(null);
    const [localidad, setLocalidad] = useState(null);
    const [cp, setCp] = useState(null);
    const [direccion, setDireccion] = useState(null);
    const [nombre, setNombre] = useState(null);

    const handleClickCerrar = () => {
        setMostrarModal(false);
        setError("");
    }

    const handleClickVer = async () => {
        try {
            const respuesta = await axiosInstance.get(`${process.env.REACT_APP_URLAPI}/Pedidos/get-info-pedido?delegacion=1&empresa=1&` +
                `cliente=${pedido.cd_cliente}&ejercicio=${pedido.ejercicio}&serie=${pedido.serie}&pedido=${pedido.cd_pedven}`);


            setCp(respuesta.data.cp_entrega)
            setNombre(respuesta.data.nombre_entrega)
            setPais(respuesta.data.pais_entrega)
            setProvincia(respuesta.data.provincia_entrega)
            setLocalidad(respuesta.data.localidad_entrega)
            setDireccion(respuesta.data.direccion_entrega)

            if (respuesta.data.lineas) {
                setLineasPedido(respuesta.data.lineas);
                setMostrarModal(true)
            } else {
                setError("No se pudieron encontrar las lineas del pedido")
            }
        } catch (error) {
            setError(error.message)
        }
    }


    useEffect(() => {
        if (refBotonAlbaran.current === null) return;

        if (pedido.situacion !== "F")
            refBotonAlbaran.current.disabled = true;

        const getAlbaranesAsociados = async () => {

            const respuesta = await axiosInstance.get(`${process.env.REACT_APP_URLAPI}/Albaranes/GetAlbaranesAsociados?` +
                `ejercicio=${pedido.ejercicio}&serie=${pedido.serie}&pedido=${pedido.cd_pedven}&cliente=${pedido.cd_cliente}`);

            if (respuesta.status === 200) {
                if (respuesta.data.albaran === '')
                    refBotonAlbaran.current.disabled = true;

                setAlbaran(respuesta.data);
            }
        }

        getAlbaranesAsociados()

        //eslint-disable-next-line
    }, [pedido])


    const handleClick = async () => {
        const respuesta = await axiosInstance.get(`${process.env.REACT_APP_URLAPI}/Albaranes/GetPDFAlbaran?` +
            `ejercicio=${pedido.ejercicio}&serie=${pedido.serie}&pedido=${pedido.cd_pedven}&cliente=${pedido.cd_cliente}`,
            { responseType: 'arraybuffer' });

        if (respuesta.status === 200) {
            const pdfObject = URL.createObjectURL(new Blob([respuesta.data], {
                type: "application/pdf"
            }))

            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                const a = document.createElement('A');
                a.href = pdfObject;
                a.download = respuesta.headers['filename'];
                a.click();
            } else {
                setPdf(pdfObject);
                setMostrarPdf(true);
            }
        }
    }


    return (
        <Fragment>
            <ModalP
                mostrarModal={mostrarModal || error}
                setMostrarModal={setMostrarModal}
                titulo={error ? "Error" : `Detalles del pedido`}
                onCancelar={handleClickCerrar}
            >
                {error 
                ? 
                    <h1 style={{ color: 'red' }}>{error}</h1>
                :
                    <>
                        <div className="grid-lineasPedidoD">
                            <div className="grid-head">Pais de entrega</div>
                            <div className="grid-head">Provincia de entrega</div>
                            <div className="grid-head" >Localidad de entrega</div>
                            <div className="grid-head" >Cp entrega</div>
                            <div className="grid-head" >Direccion de entrega</div>
                            <div className="grid-head"  >Nombre de entrega</div>
                            <div className=" container1"><span>{pais}</span></div>
                            <div className=" container1"><span>{provincia}</span></div>
                            <div className=" container1" ><span>{localidad}</span></div>
                            <div className=" container1" ><span>{cp}</span></div>
                            <div className=" container1"><span>{direccion}</span></div>
                            <div className=" container1"><span>{nombre}</span></div>
                        </div>

                        <div style={{ marginBottom: '20px' }}></div>

                        <div className="grid-lineasPedido">
                            <div className="grid-head">Código</div>
                            <div className="grid-head">Descripción</div>
                            <div className="grid-head">Cantidad</div>
                            {lineasPedido && lineasPedido.map((p, index) => (
                                <>
                                    <span key={`${index}`} className={`grid-value ${(index % 2 === 0 ? "lineaPar" : "lineaImpar")}`}>{p.codigo}</span>
                                    <span key={`${index}`} className={`grid-value ${(index % 2 === 0 ? "lineaPar" : "lineaImpar")}`}>{p.descripcion}</span>
                                    <span key={`${index}`} className={`grid-value ${(index % 2 === 0 ? "lineaPar" : "lineaImpar")}`} >{p.cantidad}</span>
                                </>


                            ))}
                        </div>
                    </>
                }
            </ModalP>
            {mostrarPdf ?
                <ModalPdf
                    pdf={pdf}
                    mostrarPdf={mostrarPdf}
                    setMostrarPdf={setMostrarPdf}
                />

                : null}
            <div className={(esPar === 0 ? "lineaPar" : "lineaImpar") + " grid-value"}><span>{pedido.fecha}</span></div>
            <div className={(esPar === 0 ? "lineaPar" : "lineaImpar") + " grid-value"}><span>{pedido.cd_pedven}</span></div>
            <div className={(esPar === 0 ? "lineaPar" : "lineaImpar") + " grid-value"}><span>{pedido.ejercicio}</span></div>
            <div className={(esPar === 0 ? "lineaPar" : "lineaImpar") + " grid-value"}><span>{pedido.serie}</span></div>
            <div className={(esPar === 0 ? "lineaPar" : "lineaImpar") + " grid-value"}><span>{pedido.cd_almacen}</span></div>
            <div className={(esPar === 0 ? "lineaPar" : "lineaImpar") + " grid-value"}><span>{`${pedido.cd_cliente}: ${pedido.nombre_cliente}`}</span></div>
            <div className={(esPar === 0 ? "lineaPar" : "lineaImpar") + " grid-value"}><span>{pedido.situacion === "F" ? "Finalizado" : pedido.situacion === "P" ? "Pendiente" : "Bloqueado"}</span></div>
            <div className={(esPar === 0 ? "lineaPar" : "lineaImpar") + " grid-value text-left"}><span className="grid-cell-start">{pedido.sureferencia}</span></div>
            <div className={(esPar === 0 ? "lineaPar" : "lineaImpar") + " grid-value"}><span>{pedido.referencia}</span></div>
            <div className={(esPar === 0 ? "lineaPar" : "lineaImpar") + " grid-value"}><span>{pedido.fecha_entrega}</span></div>
            <div className={(esPar === 0 ? "lineaPar" : "lineaImpar") + " grid-value"}><span>{albaran.albven}</span></div>
            <div className={(esPar === 0 ? "lineaPar" : "lineaImpar") + " grid-value"}>
                <div><button className="btn btn-albaran btn-pd-v-5" onClick={handleClickVer} >Ver</button></div>
                <button onClick={handleClick} ref={refBotonAlbaran} type="button" className="btn btn-albaran btn-pd-v-5">Albarán</button>
            </div>
        </Fragment>
    );
}

export default Pedido;