const LoteArticulo = ({lote, setLotesSeleccionados}) => {

  const handleChange = (e) => {
      setLotesSeleccionados(lotesSeleccionados =>  {
        return lotesSeleccionados.map(loteMap => {
          if (loteMap.cd_lote === lote.cd_lote) {
            let unidades = parseFloat(e.target.value);
            if (parseFloat(e.target.value) > lote.existencias2)
              unidades = parseFloat(lote.existencias2)
            if (parseFloat(e.target.value) < 0)
              unidades = 0

            loteMap["unidades"] = unidades;
          }

          return loteMap;
        })
      });
  }

  return ( 
    lote &&
    <>
      <div className={"datosArticulo codigoArticulo"}><span>{lote.lote_proveedor}</span></div>
      <div className={"datosArticulo codigoArticulo"}><span>{lote.fecha_caducidad}</span></div>
      <div className={"datosArticulo codigoArticulo"}><span>{lote.existencias2}</span></div>
      <input onChange={handleChange} type="number" min={0} step={1} defaultValue={1} max={lote.existencias2} name="unidades" value={lote.unidades} />
    </>
   );
}
 
export default LoteArticulo;