const formatFecha = (fechaOrigen, sumaDias, formato = 0) => {
    const fecha = new Date(fechaOrigen);
    fecha.setDate(fecha.getDate() + sumaDias);
    let ano = fecha.getFullYear();
    let mes = fecha.getMonth() + 1;
    if (mes < 10) {
        mes = '0' + mes;
    }
    let dia = fecha.getDate();
    if (dia < 10) {
        dia = '0' + dia;
    }

    switch (formato) {
        case 0:
            return ano + '-' + mes + '-' + dia;
        case 1:
            return dia + '-' + mes + '-' + ano;
    
        default:
            return ano + '-' + mes + '-' + dia;
    }
}

/**
 * Compara la fecha de dos pedidos y los ordena de forma ascendente, en caso de haber fechas similares
 * ordena por el número de pedido
 * @param {String} campo  Campo de fecha a ordenar
 * @param {Object} pedidoA Objeto correspondiente al primer pedido
 * @param {Object} pedidoB Objeto correspondiente al segundo pedido
 * @returns {Number} Devuelve si el pedido es mayor 1, menor -1 o igual 0 en orden acendente
 */
const ordenarFechaAscendente = (campo, pedidoA, pedidoB) => {

    if (Date.parse(pedidoA[campo]) > Date.parse(pedidoB[campo])) {
        return 1;
    }

    if (Date.parse(pedidoA[campo]) < Date.parse(pedidoB[campo])) {
        return -1;
    }

    if (Date.parse(pedidoA.cd_pedven) < Date.parse(pedidoB.cd_pedven)) {
        return -1;
    }

    if (Date.parse(pedidoA.cd_pedven) > Date.parse(pedidoB.cd_pedven)) {
        return 1;
    }

    return 0;
}

/**
 * Compara la fecha de dos pedidos y los ordena de forma descendente, en caso de haber fechas similares
 * ordena por el número de pedido
 * @param {String} campo  Campo de fecha a ordenar
 * @param {Object} pedidoA Objeto correspondiente al primer pedido
 * @param {Object} pedidoB Objeto correspondiente al segundo pedido
 * @returns {Number} Devuelve si el pedido es mayor 1, menor -1 o igual 0 en orden descendente
 */
const ordenarFechaDescendente = (campo, pedidoA, pedidoB) => {
    
    if (Date.parse(pedidoA[campo]) > Date.parse(pedidoB[campo])) {
        return -1;
    }

    if (Date.parse(pedidoA[campo]) < Date.parse(pedidoB[campo])) {
        return 1;
    }

    if (Date.parse(pedidoA.cd_pedven) > Date.parse(pedidoB.cd_pedven)) {
        return -1;
    }

    if (Date.parse(pedidoA.cd_pedven) < Date.parse(pedidoB.cd_pedven)) {
        return 1;
    }

    return 0;
}
 
export {formatFecha, ordenarFechaAscendente, ordenarFechaDescendente};