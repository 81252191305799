import { Fragment } from "react";

const Loading = () => {
    return ( 
        <Fragment>
            <div className="loading-background"></div>
            <div className="loading-box shadow-box-loading">
                <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
            </div>
        </Fragment>
     );
}
 
export default Loading;