import { Fragment, useContext } from "react";
import PedidoContext from "../context/PedidoContext";

function Cliente() {

    const {cliente} = useContext(PedidoContext);

    return(
        (Object.keys(cliente).length > 0 ?
            <Fragment>
                <div className="grid-cliente">
                    <div className="CodigoCliente grid-label">Código de cliente</div>
                    <div className="TipoIdentificacion grid-label">Tipo de identificación</div>
                    <div className="Identificacion grid-label">Identificación</div>
                    <div className="Nombre grid-label">Nombre</div>
                    <div className="NombreFiscal grid-label">Nombre fiscal</div>
                    <div className="Contacto grid-label">Contacto</div>
                    <div className="Direccion grid-label">Dirección</div>
                    <div className="IVA grid-label">Regimen IVA</div>
                        
                    <div className="CodigoCliente">{cliente.cd_cliente ? cliente.cd_cliente : ""}</div>
                    <div className="TipoIdentificacion">{cliente.tpidentificacion ? cliente.tpidentificacion : ""}</div>
                    <div className="Identificacion">{cliente.nif_cif ? cliente.nif_cif : ""}</div>
                    <div className="Nombre">{cliente.nombre ? cliente.nombre : ""}</div>
                    <div className="NombreFiscal">{cliente.nombre_fiscal ? cliente.nombre_fiscal : ""}</div>
                    <div className="Contacto">
                        <div>{cliente.contacto ? cliente.contacto : ""}</div>
                        <div>Teléfono: {cliente.telefono1 ? cliente.telefono1 : ""}</div>
                        <div>E-mail: {cliente.correoe ? cliente.correoe : ""}</div>
                    </div>
                    <div className="Direccion">
                        <div>{cliente.direccion ? cliente.direccion : ""}</div>
                        <div>{cliente.localidad ? cliente.localidad : ""}</div>
                        <div>{cliente.cp ? cliente.cp : ""}</div>
                        <div>{cliente.provincia ? cliente.provincia : ""}</div>
                        <div>{cliente.pais ? cliente.pais : ""}</div>
                    </div>
                    <div className="IVA">{cliente.tpregimeniva ? cliente.tpregimeniva : ""}</div>
                </div>
            </Fragment>
        : null)
    )
    
}

export default Cliente;