import { Fragment, useContext } from "react";
import PedidoContext from "../context/PedidoContext";

const Fecha = () => {
    
    const {pedido, setPedido} = useContext(PedidoContext);


    const handleChange = (e) => {
        setPedido({
            ...pedido,
            cabecera: {
                ...pedido.cabecera,
                [e.target.name]: e.target.value
            }
        })
    }
    return ( 
        <Fragment>
            <div className="label">Fecha de entrega: </div>
            <div className="grid-value">
                <input onChange={handleChange} type="date" name="fecha_entrega" value={pedido.cabecera.fecha_entrega}/>
            </div>
        </Fragment>
     );
}
 
export default Fecha;