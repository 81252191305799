import * as XLSX from 'xlsx-js-style'
import { formatFecha } from '../helpers/fechas';

export const exportarArticulosExcel = (articulos, identificadorArchivo) => {
    const workBook = XLSX.utils.book_new();
    const cabeceraExcel = [];
    let tieneLotes = false;

    articulos.forEach(articulo => {
        if (articulo.lotes?.length > 0) {
            tieneLotes = true;
            return;
        }
    })
    
    if (tieneLotes) {
        cabeceraExcel.push(
            {v: 'Codigo artículo', t: 's', s: {font: {bold: true}}},
            {v: 'Descripción', t: 's', s: {font: {bold: true}}},
            {v: 'Código barras', t: 's', s: {font: {bold: true}}},
            {v: 'Código lote', t: 's', s: {font: {bold: true}}},
            {v: 'Lote', t: 's', s: {font: {bold: true}}},
            {v: 'Fecha caducidad', t: 's', s: {font: {bold: true}}},
            {v: 'Existencias', t: 's', s: {font: {bold: true}}},
            {v: 'Unidades', t: 's', s: {font: {bold: true}}},
        );
    } else {
        cabeceraExcel.push(
            {v: 'Codigo artículo', t: 's', s: {font: {bold: true}}},
            {v: 'Descripción', t: 's', s: {font: {bold: true}}},
            {v: 'Código barras', t: 's', s: {font: {bold: true}}},
            {v: 'Existencias', t: 's', s: {font: {bold: true}}},
            {v: 'Disponible', t: 's', s: {font: {bold: true}}},
            {v: 'Unidades', t: 's', s: {font: {bold: true}}},
            {v: 'Unidades disponibles', t: 's', s: {font: {bold: true}}},
        );
    }

    const contenidoExcel = []
    contenidoExcel.push(cabeceraExcel)

    articulos.forEach((articulo) => {
        if (articulo.lotes?.length > 0) {
            articulo.lotes.forEach(lote => {
                const lineaExcel = [
                    {
                        t: 's',
                        v: articulo.codigo
                    },
                    {
                        t: 's',
                        v: articulo.descripcion
                    },
                    {
                        t: 's',
                        v: articulo.codigoBarras
                    },
                    {
                        t: 's',
                        v: lote.cd_lote
                    },
                    {
                        t: 's',
                        v: lote.lote_proveedor
                    },
                    {
                        t: 'd',
                        v: lote.fecha_caducidad
                    },
                    {
                        t: 'n',
                        v: lote.existencias1
                    },
                    {
                        t: 'n',
                        v: lote.existencias2
                    },
                ]
                contenidoExcel.push(lineaExcel);
            });
        } else {
            const lineaExcel = [
                {
                    t: 's',
                    v: articulo.codigo
                },
                {
                    t: 's',
                    v: articulo.descripcion
                },
                {
                    t: 's',
                    v: articulo.codigoBarras
                },
                {
                    t: 'n',
                    v: articulo.existencias
                },
                {
                    t: 'n',
                    v: articulo.disponible
                },
                {
                    t: 'n',
                    v: articulo.unidades
                },
                {
                    t: 'n',
                    v: articulo.unidades_disponibles
                }
            ]
            contenidoExcel.push(lineaExcel);
        }
    })

    const workSheet = XLSX.utils.aoa_to_sheet(contenidoExcel)
    autosizeColumns(workSheet)
    XLSX.utils.book_append_sheet(workBook, workSheet, 'Articulos', true);
    const fecha = new Date();
    
    XLSX.writeFile(workBook, 
        `Inventario_${identificadorArchivo}_${formatFecha(fecha, 0, 1)}_${fecha.getHours()}${fecha.getMinutes()}.xlsx`)

}

const autosizeColumns = (worksheet) => {
    const jsonWorksheet = XLSX.utils.sheet_to_json(worksheet)
    if (jsonWorksheet.length > 0) {
        const medidaCols = Object.keys(jsonWorksheet[0]).map((titulo) => titulo.toString().length)
        jsonWorksheet.forEach(row => {
            Object.entries(row).forEach(([key, value], index) => {
                if (key !== "ETA")
                    medidaCols[index] = Math.max(medidaCols[index], value ? value.toString().length : 0);
                else
                    medidaCols[index] = 10;
            })
        })
        
        worksheet["!cols"] = medidaCols.map(columna => {return {wch: columna}})
    }
}