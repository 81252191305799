import { Fragment, useContext, useEffect, useState } from "react";
import AppContext from "../context/AppContext";
import PedidoContext from "../context/PedidoContext";

function Paises() {

    const {direccionEntrega, dispatchDireccionEntrega, respuestaCreacion} = useContext(PedidoContext);
    const {config, axiosInstance} = useContext(AppContext);
    const [paises, setPaises] = useState([]);

    const getPaises = async () => {
        try {
            const resp = await axiosInstance.get(`${process.env.REACT_APP_URLAPI}/Direccion/Paises`);
            if (resp.status === 200) {
                setPaises(resp.data.paises);
            }
        } catch {
            console.error("No se pudo obtener la lista de paises")
        }
    }

    //Obtiene la lista de paises disponibles
    useEffect(() => {
        getPaises();
        //eslint-disable-next-line 
    }, [config])
    
    //Selecciona por defecto españa
    useEffect(() => {
        if (paises !== undefined && paises.length > 0)
            dispatchDireccionEntrega({type: 'MODIFY_COUNTRY', pais: "ES", paises: paises});
    //eslint-disable-next-line
    }, [paises])

    //Restablece el campo de pais despues de la creación exitosa de un pedido
    useEffect(() => {
        if (respuestaCreacion.resultado !== undefined && respuestaCreacion.resultado === "1")
            dispatchDireccionEntrega({type: 'MODIFY_COUNTRY', pais: "ES", paises: paises});
    //eslint-disable-next-line
    }, [respuestaCreacion])

    //Establece el pais seleccionado
    const handleChange = (e) => {
        dispatchDireccionEntrega({type: 'MODIFY_COUNTRY', pais: e.target.value, paises: paises});
    }

    return (
        (paises.length > 0 ?
            <Fragment>
                <div className="label pais">País: </div>
                <select  className="selector pais" name="pais_direc_entrega" value={direccionEntrega.pais.cd_pais} onChange={handleChange}>
                    {paises.map((pais, index) => (
                        <option key={index} value={pais.cd_pais}>{pais.nombre}</option>
                    ))}
                </select>
            </Fragment>
        : null)
    );
}

export default Paises;