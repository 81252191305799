import { useEffect, useRef } from "react";
import BotonesAceptarCancelar from "./BotonesAceptarCancelar";

function Modal({mostrarModal, setMostrarModal, titulo, children, onAceptar, onCancelar}) {
    
    const modalRef = useRef(null);

    //muestra u oculta el modal con la respuesta de la creacion
    useEffect(() => {
        const modal = modalRef.current;
        if (modal.current !== null) {
            if (mostrarModal)
                modal.style.display = "block";
            else
                modal.style.display = "none";
        }
        //eslint-disable-next-line 
    }, [mostrarModal])

    //Cierra la ventana modal
    const handleClick = () => {
        setMostrarModal(false);
    }
    
    return (
            <div
                ref={modalRef}
                className="modal"
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title">{titulo}</h3>
                        <div onClick={handleClick} className="cerrar cruz-cerrar-modal"><span>&times;</span></div>
                    </div>
                    <div className="modal-body">
                        {children}
                    </div>
                    
                <BotonesAceptarCancelar
                    onAceptar={onAceptar}
                    onCancelar={onCancelar}
                />
                </div>
            </div>
    );
  }

  export default Modal;