
import { useContext, useEffect, useReducer, useRef, useState } from "react";
import { reducerArticulosPedido, initArticulosPedido } from '../reducers/ReducerArticulosPedido';
import { reducerDireccionEntrega, initDireccionEntrega } from '../reducers/ReducerDireccionEntrega';
import { formatFecha } from "../helpers/fechas";
import PedidoContext from "../context/PedidoContext";
import Errores from "./Errores";
import Almacenes from "./Almacenes";
import ArticulosPedido from "./ArticulosPedido";
import Clientes from "./Clientes";
import DireccionEntrega from "./DireccionEntrega";
import DatosPedido from "./DatosPedido";
import ModalRespuestaCreacion from "./ModalRespuestaCreacion";
import Fecha from "./Fecha";
import Loading from "./Loading";
import AppContext from "../context/AppContext";

function CrearPedido() {
    
    const { config, loading, axiosInstance } = useContext(AppContext);

    const refBotonCrearPedido = useRef(null);

    const [errores, setErrores] = useState({});
    const [mostrarErrores, setMostrarErrores] = useState(false);
    const [serieAlmacen, setSerieAlmacen] = useState(0);
    const [cliente, setCliente] = useState({});
    const [direccionEntrega, dispatchDireccionEntrega] = useReducer(reducerDireccionEntrega, {}, initDireccionEntrega);
    const [articulos, setArticulos] = useState([]);
    const [articulosPedido, dispatchArticulosPedido] = useReducer(reducerArticulosPedido, [], initArticulosPedido);
    const [empresaDelegacion, setEmpresaDelegacion] = useState({
        empresa: 1,
        delegacion: "1"
    });

    const [pedido, setPedido] = useState({
        lineas: {
            linea: []
        },
        cabecera: {
            fecha_entrega: formatFecha(Date.now(), 1),
            descuentos_doc: "",
            observaciones: "",
            cd_agencia_envio: "1", 
            cd_formapago: "01",
            valor_portes: "",
            forma_envio: "",
            descuentos_finan: "",
            sureferencia: formatFecha(Date.now(), 1, 1),
            cd_almacen: serieAlmacen,
            cd_plataforma_instalacion: "",
            fecha_pedido: formatFecha(Date.now(), 0),
            ignorar_repetidos: "1",
            tp_portes: "",
            num_pedido_web: "Pedido web", 
            calcular_condiciones: "0",
            servir_completo: "N"
        }
    })

    const [respuestaCreacion, setRespuestaCreacion] = useState({});
    const [mostrarModal, setMostrarModal] = useState(false);

    //Petición para crear el pedido
    const crearPedido = async () => {
        const combinacionPedido = {
            cabecera: {
                ...pedido.cabecera,
                num_pedido_web: `${pedido.cabecera.num_pedido_web} ${Date.now()}`,
                nombre_direc_entrega: direccionEntrega.nombre_direc_entrega,
                direccion_direc_entrega: direccionEntrega.direccion_direc_entrega,
                poblacion_direc_entrega: direccionEntrega.poblacion_direc_entrega,
                cp_direc_entrega: direccionEntrega.cp_direc_entrega,
                telefono_direc_entrega: direccionEntrega.telefono_direc_entrega,
                email_direc_entrega: direccionEntrega.email_direc_entrega,
                pais_direc_entrega: direccionEntrega.pais.nombre,
                provincia_direc_entrega: direccionEntrega.provincia.nombre
            },
            lineas: {linea: [...articulosPedido]}
        }
        
        const resp = await axiosInstance.put(
            `${process.env.REACT_APP_URLAPI}/Pedidos/AceptacionPedido`,
            `json=${encodeURIComponent(JSON.stringify(combinacionPedido))}&empresa=${empresaDelegacion.empresa}&delegacion=${empresaDelegacion.delegacion}`);
        if ( resp.status === 200 ) {
            setRespuestaCreacion(resp.data);
            if (resp.data.resultado !== undefined && resp.data.resultado  === "1") {
                const pedidoLimpio = {
                    ...pedido,
                    cabecera: {
                        ...pedido.cabecera,
                        num_pedido_web: "Pedido web",
                        fecha_entrega: formatFecha(Date.now(), 1),
                        sureferencia: formatFecha(Date.now(), 1, 1),
                    }
                }
                setPedido(pedidoLimpio);
            }

            if (resp.data.resultado !== undefined)
                setMostrarModal(true);
            
        } 
    }

    //Cuando varía el cliente o el almacen modifica la cabecera del pedido para asignar la serie y cliente seleccionado
    useEffect(() => {
        const datosCabecera = {
            fecha_pedido: formatFecha(Date.now(), 0),
            cd_cliente: cliente.cd_cliente,
            cd_almacen: serieAlmacen.toString(),
            cd_agencia_envio: cliente.cd_agtransporte
        }
        if (config.proyecto !== undefined && config.proyecto === "Cepever") {
            setEmpresaDelegacion({
                ...empresaDelegacion,
                delegacion: serieAlmacen
            })
        } else {
            setEmpresaDelegacion({
                ...empresaDelegacion,
                delegacion: "1"
            })
        }
        setPedido({
            ...pedido,
            cabecera: {...pedido.cabecera, ...datosCabecera}
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cliente, serieAlmacen])

    useEffect(() => {
        if(refBotonCrearPedido.current !== null &&!mostrarModal)
            refBotonCrearPedido.current.disabled = false;
    }, [mostrarModal])

    //Muestra u oculta los errores
    const handleClick = () => {

        refBotonCrearPedido.current.disabled = true;
        if (pedido.cabecera.num_pedido_web === "" ||
        pedido.cabecera.fecha_entrega === "" ||
        articulosPedido.length === 0 ||
        direccionEntrega.nombre_direc_entrega === "" ||
        direccionEntrega.direccion_direc_entrega === "" ||
        direccionEntrega.poblacion_direc_entrega === "" ||
        direccionEntrega.cp_direc_entrega === "" ||
        direccionEntrega.pais.nombre === "" ||
        direccionEntrega.provincia.nombre === "") {
            setMostrarErrores(true);
            refBotonCrearPedido.current.disabled = false;
        } else {
            setMostrarErrores(false);
            crearPedido();
        }
    }

    return (

        <PedidoContext.Provider
          value={{
              cliente,
              direccionEntrega,
              articulos,
              articulosPedido,
              pedido,
              respuestaCreacion,
              empresaDelegacion,
              serieAlmacen,
              setCliente,
              setArticulos,
              dispatchDireccionEntrega,
              dispatchArticulosPedido,
              setPedido,
              setSerieAlmacen
          }}
        >
                <ModalRespuestaCreacion
                    mostrarModal={mostrarModal}
                    setMostrarModal={setMostrarModal}
                />
                
                {config.proyecto !== undefined ?
                    <div className="cuerpo">
                        {loading ? <Loading/> : null }
                        <section className="shadow-box">
                            <h3>Datos pedido:</h3>
                            <div className="grid-pedidoWebAlmacen">
                                <Almacenes
                                    setSerieAlmacen={setSerieAlmacen}
                                />
                                <DatosPedido/>
                                <Fecha />
                            </div>
                        </section>
                        <section className="shadow-box">
                            <h3>Direccion de entrega:</h3>
                            <DireccionEntrega />
                        </section>
                        <section className="shadow-box">
                            <h3>Selección de cliente:</h3>
                            <Clientes/>
                        </section>
                        <section className="shadow-box articulos-pedido">
                            <h3>Articulos pedido:</h3>
                            <ArticulosPedido/>
                        </section>
                        
                        <Errores
                            mostrarErrores={mostrarErrores}
                            setMostrarErrores={setMostrarErrores} 
                            errores={errores}
                            setErrores={setErrores}
                        />

                        {articulosPedido.length > 0 ?
                            <div>
                                <button ref={refBotonCrearPedido} className="btn btn-crear-pedido btn-primary" type="submit" onClick={handleClick}>Crear pedido</button>
                            </div>
                        :null}
                            
                    </div>
                : null}
        </PedidoContext.Provider>

    );
}

export default CrearPedido;