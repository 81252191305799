import { Fragment, useContext, useEffect, useRef, useState } from "react";
import PedidoContext from "../context/PedidoContext";
import ArticuloPedido from "./ArticuloPedido";
import Articulos from "./Articulos";
import Modal from "./Modal";
import SelectorLoteArticulo from "./SelectorLoteArticulo";

function ArticulosPedido() {

    const {config, articulos, articulosPedido, dispatchArticulosPedido, respuestaCreacion} = useContext(PedidoContext);
    const [articulo, setArticulo] = useState({});
    const [mostrarModal, setMostrarModal] = useState(false);
    const botonAgregar = useRef(null);

    //Cada vez que se cambia de configuración limpia la lista de articulos del pedido
    useEffect(() => {
        dispatchArticulosPedido({type: 'RESET'});
        //eslint-disable-next-line 
    }, [config])

    //activa o desactiva el boton de añadir articulos a la lista segun si hay articulos disponibles para añadir
    useEffect(() => {
        if (botonAgregar.current !== null) {
            if (articulo !== undefined)
                botonAgregar.current.disabled = false;
            else
                botonAgregar.current.disabled = true;
        }
        //eslint-disable-next-line 
    }, [articulo])


    //Borra la lista de articulos del pedido una vez se creó
    useEffect(() => {
        if (respuestaCreacion.resultado !== undefined && respuestaCreacion.resultado === "1") 
            dispatchArticulosPedido({type: 'RESET'});
    //eslint-disable-next-line
    }, [respuestaCreacion])

    const handleClick = (e) => {
        e.preventDefault();
        if (articulo?.lotes?.length  > 0) {
            setMostrarModal(true);
        } else {
            dispatchArticulosPedido({type: 'ADD_ITEM', articulo: articulo})
        }
    }

    const handleClickAceptar = () => {
        dispatchArticulosPedido({type: 'ADD_ITEM', articulo: articulo});
        setArticulo({
            ...articulo,
            lotesSeleccionados: []
        });
        setMostrarModal(false);
    }

    const handleClickCerrar = () => {
        setArticulo({
            ...articulo,
            lotesSeleccionados: []
        });
        setMostrarModal(false);
    }

    return (
        <Fragment>
            <Modal
            mostrarModal={mostrarModal}
            setMostrarModal={setMostrarModal}
            titulo={`Selecciona los lotes del artículo ${articulo.codigo}`}
            onAceptar={handleClickAceptar}
            onCancelar={handleClickCerrar}
            >
                <SelectorLoteArticulo
                articulo={articulo}
                setArticulo={setArticulo}
                key={articulo+mostrarModal}
                />
            </Modal>
            <div className="grid-articulos">
                <Articulos 
                    articuloSeleccionado={articulo}
                    setArticulo={setArticulo}
                />
                
                {articulos.length > 0 ?
                    <div><button ref={botonAgregar} className="btn btn-primary btn-articulo btn-pd-v-5" onClick={handleClick} >Añadir articulo</button></div>
                : null }
            </div>
            {articulosPedido.length > 0 ?
                <div className="seccionLineas"><p className="grid-label">Lineas de pedido:</p>
                        <div className="grid-container">
                            <div className="cabecera grid-head">Código articulo</div>
                            <div className="cabecera grid-head">Descripción</div>
                            <div className="cabecera grid-head">Referencia proveedor</div>
                            <div className="cabecera grid-head">Código barras</div>
                            <div className="cabecera grid-head">Existencias</div>
                            <div className="cabecera grid-head">Disponible</div>
                            <div className="cabecera grid-head">Unidades venta</div>
                            <div className="cabecera grid-head">Eliminar</div>
                            {articulosPedido.map((articulo, index) => (
                            <ArticuloPedido key={index}
                                posicion={index}
                                articulo={articulo}
                            />
                        ))}
                        </div>
                        
                </div>
            :null }
        </Fragment>
    );
}

export default ArticulosPedido;