import { useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppContext from '../context/AppContext';
import logoEinsa from "../images/logo_einsa_sourcing.svg"

function Cabecera(props) {

    const {config, redirigirLogin, setToken, setConfig, setRedirigirLogin} = useContext(AppContext)
    const menu = useRef(null);

    useEffect(() => {
        if (menu.current !== null) {
            const menuRef = menu.current;
            if (redirigirLogin) {
                menuRef.classList.add('hide')
            } else {
                if (props.titulo !== 'Login') {
                    menuRef.classList.remove('hide')
                } else {
                    menuRef.classList.add('hide')
                }
            }
        }
        //eslint-disable-next-line
    }, [props.titulo])

    const handleClickMenu = () => {
        if(props.main.current !== null) {
            props.main.current.classList.toggle('side-nav-show')
        }
    }

    const handleClickLogOff = () => {
        sessionStorage.clear();
        setConfig({})
        setToken({});
        setRedirigirLogin(true);
    }

    return (
        <div className="cabecera">
            <section className="shadow-box">
                <div className="flex">
                    <div ref={menu} className="box-menu">
                        <FontAwesomeIcon size="2x" className="hamburger-menu" icon={['fa', 'bars']} onClick={handleClickMenu}/>
                    </div>
                    <div className="logoEinsa"><img className="" src={logoEinsa} alt="logo einsa"/></div>
                    <div className="titulo">
                        <h2>
                            <span>{props.titulo}</span>
                        </h2>
                    </div>
                    <div className={`login ${config.usuario ? "empresa-in" : "empresa-out"}`}>
                        <span>{`Usuario: ${config.usuario}`}</span>
                        <div className="btn-align-right" ><button type="button" className="btn btn-danger btn-pd-v-5 btn-mg-v-5" onClick={handleClickLogOff} >Salir</button></div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Cabecera;